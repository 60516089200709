@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities"; 

@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap");

body {
  margin: 0;
  font-family: "IBM Plex Sans", BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.leaflet-container {
  height: 100%;
  z-index: 0;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
